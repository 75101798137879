import React from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

// Images for swipeable projects
import fratSwipe1 from '../assets/img/fratSwipe1.png';
import fratSwipe2 from '../assets/img/fratSwipe2.png';
import fratSwipe3 from '../assets/img/fratSwipe3.png';
import gradJump1 from '../assets/img/gradJump1.png';
import gradJump2 from '../assets/img/gradJump2.png';
import gradJump3 from '../assets/img/gradJump3.png';
import gradJump4 from '../assets/img/gradJump4.png';
import hairShop1 from '../assets/img/hairShop1.png';
import hairShop2 from '../assets/img/hairShop2.png';
import hairShop3 from '../assets/img/hairShop3.png';
import hairShop4 from '../assets/img/hairShop4.png';
import projImg5 from '../assets/img/Fitfinity.gif';
import projImg4 from '../assets/img/ctg.gif';
import ecomGif from '../assets/img/ecom.gif';
import bookingGif from '../assets/img/booking.gif';

// Styled Components for the Projects section
const ProjectsWrapper = styled.section`
  background: linear-gradient(135deg, #6a1b9a, #8e44ad);
  color: #fff;
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ProjectTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #fff;
`;

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ProjectCard = styled.div`
  background: #fff;
  color: #333;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
`;

const ProjectImage = styled.img`
  width: 250px;
  height: 250px;
  margin-bottom: 1.5rem;
  border-radius: 12px;
`;

const ProjectDescription = styled.p`
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
  color: #333;
`;

// Swipeable Project Component
const SwipeableProjectCard = ({ title, description, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentImageIndex((prev) => (prev + 1) % images.length),
    onSwipedRight: () => setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length),
  });

  const nextImage = () => setCurrentImageIndex((prev) => (prev + 1) % images.length);
  const prevImage = () => setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);

  return (
    <ProjectCard {...handlers}>
      <div style={{ position: 'relative', width: '250px', height: '250px' }}>
        <ProjectImage src={images[currentImageIndex]} alt={title} />
        <button
          onClick={prevImage}
          style={{
            position: 'absolute',
            left: '-40px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            padding: '0.5rem 1rem',
            cursor: 'pointer',
            borderRadius: '50%',
            zIndex: 10, // Ensure button stays on top
          }}
        >
          {'<'}
        </button>
        <button
          onClick={nextImage}
          style={{
            position: 'absolute',
            right: '-40px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            padding: '0.5rem 1rem',
            cursor: 'pointer',
            borderRadius: '50%',
            zIndex: 10, // Ensure button stays on top
          }}
        >
          {'>'}
        </button>
      </div>
      <ProjectDescription>
        <strong>{title}</strong><br />
        {description}
      </ProjectDescription>
    </ProjectCard>
  );
};

// Project data including swipeable and static projects
const projects = [
  {
    title: "FratSwipe",
    description: "Fraternity Rush Application",
    images: [fratSwipe1, fratSwipe2, fratSwipe3],
  },
  {
    title: "GradJump",
    description: "Graduate Collaboration Platform",
    images: [gradJump3, gradJump4, gradJump1, gradJump2],
  },
  {
    title: "HairShop",
    description: "E-commerce Platform for Hair Products",
    images: [hairShop1, hairShop2, hairShop3, hairShop4],
  },
  {
    title: "Fitness Tracker",
    description: "Mobile Fitness Application",
    imgUrl: projImg5,
  },
  {
    title: "Hackathon 2nd Place Submission",
    description: "Morgan Stanley's Code To Give",
    imgUrl: projImg4,
  },
  {
    title: "E-commerce Site",
    description: "A full-stack e-commerce site",
    imgUrl: ecomGif,
  },
  {
    title: "Booking App",
    description: "An iOS app(Swift) for appointment booking",
    imgUrl: bookingGif,
  },
];

const Projects = () => {
  return (
    <ProjectsWrapper id="projects">
      <ProjectTitle>Recent Projects</ProjectTitle>
      <ProjectsContainer>
        {projects.map((project, index) =>
          project.images ? (
            <SwipeableProjectCard
              key={index}
              title={project.title}
              description={project.description}
              images={project.images}
            />
          ) : (
            <ProjectCard key={index}>
              <ProjectImage src={project.imgUrl} alt={project.title} />
              <ProjectDescription>
                <strong>{project.title}</strong><br />
                {project.description}
              </ProjectDescription>
            </ProjectCard>
          )
        )}
      </ProjectsContainer>
    </ProjectsWrapper>
  );
};

export default Projects;
